/*
From: https://gitlab.com/zapier/fb-embed-frontend/-/blob/staging/packages/service/src/hooks/use-accounts.ts
*/
import {
  Account,
  V4AccountsResponse,
  V4SessionResponse,
} from "@/types/ZapierV4";
import { V4_ACCOUNTS, V4_SESSION } from "@/utils/constants";

import useZapierSWR from "./useZapierSwr";

// Finds the "primary" account id by checking for a role of "owner"
// then uses the lowest id value if multiple are available. If no
// account has a role of "owner", return the first account_id or null.
const findPrimaryAccountId = (session: V4SessionResponse | undefined) => {
  const roles = session?.roles || [];
  const ownedAccounts = roles
    .filter(({ role }) => role === "owner")
    .map(({ account_id }) => account_id);

  if (ownedAccounts.length > 0) {
    return Math.min(...ownedAccounts);
  }
  return roles[0] && roles[0].account_id ? roles[0].account_id : null;
};

const useAccounts = function () {
  const { data: accounts, error: accountsError } =
    useZapierSWR<V4AccountsResponse>(V4_ACCOUNTS);

  const { data: session, error: sessionError } =
    useZapierSWR<V4SessionResponse>(V4_SESSION);

  if (accountsError || sessionError) {
    return { isLoadingAccounts: true, error: accountsError || sessionError };
  }

  if (!session || !accounts) return { isLoadingAccounts: true };

  const primaryAccountId = findPrimaryAccountId(session);

  const formatAccounts = accounts?.results?.map((account: Account) => {
    const email = session.email;
    const imageUrl =
      account.id === primaryAccountId ? session.photo_url : account.logo_url;
    const name =
      account.id === primaryAccountId
        ? account.owner.name || "Personal Account"
        : account.name;
    return { ...account, email, imageUrl, name };
  });

  return {
    accounts: formatAccounts,
    isLoadingAccounts: false,
    accountsError,
  };
};

export default useAccounts;
